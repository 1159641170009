import React, { useEffect, useState } from "react";
import heroImage from "../hero-image.svg";
import downloadIcon from "../download.svg";
import Siderbar from "./Siderbar";
import Header from "./Header";
import Footer from "./Footer";

const Home = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch("https://njom-426013.as.r.appspot.com/api/articles")
      .then((response) => response.json())
      .then((data) => setArticles(data))
      .catch((error) => console.error("Error fetching articles:", error));
  }, []);

  return (
    <div className="container" style={styles.mainCard}>
      <div className="row justify-content-center">
        <div className="col-11">
          <div style={styles.mainContent}>
            <div className="row">
              <div>
                <Header />
              </div>
              <div className="col-lg-9">
                {/* Hero Banner */}
                <div className="hero-banner" style={styles.heroBanner}>
                  <img
                    src={heroImage}
                    alt="Hero Banner"
                    style={styles.heroImage}
                  />
                </div>

                <div className="sj-aboutus mt-4">
                  <div
                    className="sj-introduction sj-sectioninnerspace"
                    style={styles.head}
                  >
                    <h4>A Brief Introduction</h4>
                    <div className="sj-description">
                      <p>
                        NIET Journal of Management (NIETJOM) is a peer-reviewed
                        open access journal published twice yearly in English
                        that provides a global platform for the publication and
                        dissemination of academic and practice-oriented papers
                        dealing with advanced industrial concerns (business
                        management, industrial engineering & management,
                        information management & applications and service
                        management, etc.). The Journal publishes articles on all
                        aspects of management, business, trade, and economy. All
                        papers must present original, previously unpublished
                        research, with experimental or theoretical results.
                        Articles submitted must match these requirements and
                        must not be in the process of being published elsewhere.
                        Manuscripts must adhere to the journal's style and are
                        subject to both review and editing. A paper can be
                        rejected by the Editorial Board at any time. It is the
                        prime responsibility of the author to gain proper
                        authorization for the use of any copyrighted materials
                        in the manuscript, prior to the submission of the
                        manuscript to NIETJOM.
                      </p>
                    </div>
                  </div>
                  <div className="sj-ourhistory sj-sectioninnerspace"></div>
                </div>

                <div className="articles mt-4">
                  {articles.map((article, index) => (
                    <div
                      key={index}
                      className="article mb-3"
                      style={styles.article}
                    >
                      <h3 style={styles.articleTitle}>{article.title}</h3>
                      <p style={styles.articleText}>{article.abstract}</p>
                      <div style={styles.buttons}>
                        <button style={styles.button}>
                          <img
                            src={downloadIcon}
                            alt="Download"
                            style={styles.icon}
                          />{" "}
                          View PDF
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-3">
                <Siderbar />
              </div>
              <div className="mt-5">
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainCard: {
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    border: "1px solid #ddd",
    width: "100%",
  },

  mainContent: {
    padding: "15px",
    width: "100%",
  },
  heroBanner: {
    backgroundColor: "#f8f9fa",
    textAlign: "center",
    marginTop: "20px",
  },
  heroImage: {
    width: "100%",
    maxWidth: "800px",
  },
  head: {
    textAlign: "center",
  },
  article: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
  },
  articleTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  articleText: {
    fontSize: "1rem",
    lineHeight: "1.5",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "white",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },
  icon: {
    marginRight: "5px",
    width: "16px",
  },
  sideBar: {
    width: "250px",
  },
};

export default Home;
