import React from "react";
import { Link } from "react-router-dom";
import Siderbar from "./Siderbar";
import Header from "./Header";
import "./styles.css";
import Footer from "./Footer";

const EditorialBoard = () => {
  return (
    <div className="container" style={styles.mainCard}>
      <div className="row justify-content-center">
        <div className="col-11">
          <div style={styles.mainContent}>
            <div className="row">
              <div>
                <Header />
              </div>
              <div className="col-lg-9">
                <div className="editorial-board mt-4">
                  <h3 style={styles.title}>Editorial Board</h3>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/1.png"
                          alt="Mr. Praveen Soneja"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Mr. Praveen Soneja</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Director General NIET
                              <br />
                              Greater Noida(U.P)
                              <br />
                              India
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/2.png"
                          alt="Prof. (Dr.) Vinod M. Kapse"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Prof. (Dr.) Vinod M. Kapse</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Director NIET
                              <br />
                              Greater Noida(U.P)
                              <br />
                              India
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/3.png"
                          alt="Mr. Raman Batra"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Mr. Raman Batra</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Executive Vice-President, NIET
                              <br />
                              Greater Noida(U.P)
                              <br />
                              India
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/4.png"
                          alt="Dr. Ayozie Daniel Ogechukwu"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Ayozie Daniel Ogechukwu</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Toward Better-Quality Compounded Drugs — An
                              Update form
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <p>
                            Consectetur adipisicing elit sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua ut enim
                            ad minim veniam quis nostrud exercitation ullamco...
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/5.png"
                          alt="Dr Ayozie Daniel Ogechukwu"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr Ayozie Daniel Ogechukwu</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Department of Business Adminstration Federal
                              Uninversity
                              <br />
                              Lokoja P.m.b 1154 Lokoja Kogi Street
                              <br />
                              Nigeria
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:ayozieogechukwu@fulokoja.edu.ng">
                            ayozieogechukwu@fulokoja.edu.ng
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/6.png"
                          alt="Dr. Behnam Khosrozadeh"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Behnam Khosrozadeh</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Malekan Branch
                              <br />
                              Islamic azad universiry
                              <br />
                              Iran
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:behnam_kh@tabrizu.ac.ir">
                            behnam_kh@tabrizu.ac.ir
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/7.png"
                          alt="Dr. CHEN, CHUN-WEI"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. CHEN, CHUN-WEI</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Department of Creative Product Design
                              <br />
                              1, Ling tung Rd.
                              <br />
                              Taichung
                              <br />
                              Taiwan, R.O.C.
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:chenschool@yahoo.com.tw">
                            chenschool@yahoo.com.tw
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/8.png"
                          alt="Dr. Dariusz Jacek Jakóbczak"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Dariusz Jacek Jakóbczak</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Department of Electronics and Computer Science
                              <br />
                              Koszalin University of Technology
                              <br />
                              Poland
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:dariusz.jakobczak@tu.koszalin.pl">
                            dariusz.jakobczak@tu.koszalin.pl
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/9.png"
                          alt="Frederica Kinnaird"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Frederica Kinnaird</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              A Milestone for CART Cells &amp; Treatment
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <p>
                            Consectetur adipisicing elit sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua ut enim
                            ad minim veniam quis nostrud exercitation ullamco...
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/10.png"
                          alt="Dr. GEOFFREY GITAU KAMAU"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. GEOFFREY GITAU KAMAU</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Senior Lecturer (Entrepreneurship &amp; ICT),
                              Department of Commerce
                              <br />
                              School of Business and Economics,Zetech University
                              <br />
                              P.O. Box 2768 - 00200
                              <br />
                              Nairobi
                              <br />
                              Kenya
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:gkamau@kabarak.ac.ke">
                            gkamau@kabarak.ac.ke
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/11.png"
                          alt="Dr. José G Vargas-Hernández"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. José G Vargas-Hernández</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Research professor Postgraduate and Research
                              Division
                              <br />
                              Instituto Tecnológico José Mario Molina Pasquel y
                              <br />
                              Henríquez, Unidad Académica Zapopan
                              <br />
                              Camino Arenero 1101 C.P. 45019
                              <br />
                              El Bajío, Zapopan, Jalisco.
                              <br />
                              Mexico
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </article>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/12.png"
                          alt="Dr. Mohamed Salem Badawi"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Mohamed Salem Badawi</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Professor of Radiation Physics and Protection
                              <br />
                              Head of Physics Department Faculty of Science
                              <br />
                              Beirut Arab University
                              <br />
                              Lebanon
                              <br />
                              Egypt
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:ms241178@hotmail.com">
                            ms241178@hotmail.com
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/13.png"
                          alt="Dr. Navneet Singh Sikarwar"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Navneet Singh Sikarwar</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Associate Dean Resarch &amp; Prof. Dept. of IT,
                              NIET
                              <br />
                              Greater Noida(U.P)
                              <br />
                              India
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:editor@Njet.org">
                            editor@Njet.org
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/14.png"
                          alt="Dr. Neema Agarwal"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Neema Agarwal</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Additional Managing Director, NIET
                              <br />
                              Greater Noida(U.P)
                              <br />
                              India
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </article>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/15.png"
                          alt="Dr. Noreddlne Gherabl"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Noreddlne Gherabl</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              National School of Applied Sciences
                              <br />
                              University Sultan Moulay Slimane
                              <br />
                              Morocco
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:n.gherabi@usms.ma">
                            n.gherabi@usms.ma
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/16.png"
                          alt="Dr. RAIMI MORUFU OLALEKAN"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. RAIMI MORUFU OLALEKAN</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Department of Community Medicine
                              <br />
                              Faculty of Clinical Sciences
                              <br />
                              College of Health Sciences
                              <br />
                              Niger Delta University
                              <br />
                              Wilberforce Island
                              <br />
                              Amassoma, Bayelsa State.
                              <br />
                              Nigeria
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:morufuolalekan.raimi@gmail.com">
                            morufuolalekan.raimi@gmail.com
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="item mb-3" style={styles.member}>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/17.png"
                          alt="Dr. Om Prakash Agarwal"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Om Prakash Agarwal</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Managing Director, NIET
                              <br />
                              Greater Noida(U.P)
                              <br />
                              India
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </article>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/18.png"
                          alt="Dr. Shahram Babaie"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Shahram Babaie</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Department of Computer Engineering, Tabriz Branch
                              <br />
                              Islamic Azad university, Tabriz
                              <br />
                              Iran
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:Sh.babaie@iaut.ac.ir">
                            Sh.babaie@iaut.ac.ir
                          </Link>
                        </div>
                      </div>
                    </article>
                    <article className="sj-post sj-editorchoice">
                      <figure className="sj-postimg">
                        <img
                          src="images/editorchoice/19.png"
                          alt="Dr. Yunisyaaf Y.Arief"
                        />
                      </figure>
                      <div className="sj-postcontent">
                        <div className="sj-head">
                          <span className="sj-username">
                            <Link to="#">Dr. Yunisyaaf Y.Arief</Link>
                          </span>
                          <h3>
                            <Link to="#">
                              Postgraduate Lecture at Perbanas Institute
                              <br />
                              Indonesia
                            </Link>
                          </h3>
                        </div>
                        <div className="sj-description">
                          <Link to="mailto:yunisyaaf.arief@perbanas.id">
                            yunisyaaf.arief@perbanas.id
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Siderbar />
              </div>
              <div>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainCard: {
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    border: "1px solid #ddd",
    width: "100%",
    margin: "0 auto",
  },

  mainContent: {
    padding: "15px",
    width: "100%",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
  },
  member: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
  },
};

export default EditorialBoard;
