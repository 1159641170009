import React, { useState } from "react";
import Siderbar from "./Siderbar";
import Header from "./Header";
import Footer from "./Footer";

export const PaperCall = () => {
  const [formData, setFormData] = useState({
    title: "",
    abstract: "",
    reference: "",
    keywords: "",
    authors: "",
    designations: "",
    affiliations: "",
    countries: "",
    emails: "",
    corresponding_email: "",
    contact_number: "",
    reviewer1_name: "",
    reviewer1_designation: "",
    reviewer1_field: "",
    reviewer1_contact: "",
    reviewer1_email: "",
    reviewer2_name: "",
    reviewer2_designation: "",
    reviewer2_field: "",
    reviewer2_contact: "",
    reviewer2_email: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    const response = await fetch(
      "https://njom-426013.as.r.appspot.com/api/articles",
      {
        method: "POST",
        body: data,
      }
    );

    const result = await response.json();
    if (result.success) {
      alert(result.message);
    } else {
      alert("Error adding article");
    }
  };

  

  return (
    <>
      <div style={styles.container}>
        <div className="container" style={styles.mainCard}>
          <div className="row justify-content-center">
            <div className="col-11">
              <div style={styles.mainContent}>
                <div className="row">
                  <div>
                    <Header />
                  </div>
                  <div className="col-lg-9 mt-4 mb-5">
                    <div id="sj-content" className="sj-content">
                      <div className="sj-aboutus">
                        <div className="sj-introduction sj-sectioninnerspace">
                          <h4>Call For Paper</h4>
                          <div className="sj-description">
                            <p>
                              <b>NIETJOM</b> is inviting all research,
                              innovation ideas and articles in the areas of
                              management.
                            </p>
                          </div>
                          <div className="sj-description">
                            <p>
                              <b>NIETJOM</b> do not charge any kind of
                              publication fees for research article submission
                              and publishing.
                            </p>
                          </div>
                          <div className="sj-description">
                            <p>
                              <b>NIETJOM</b> does not entertain any kind of
                              plagiarism.
                            </p>
                          </div>
                        </div>
                        <div className="sj-ourhistory sj-sectioninnerspace"></div>
                      </div>
                    </div>
                    <div
                      id="sj-content"
                      className="sj-content sj-addarticleholdcontent"
                    >
                      <div className="sj-dashboardboxtitle">
                        <h2>Add A New Article</h2>
                      </div>
                      <div className="sj-addarticlehold">
                        <form
                          className="sj-formtheme sj-formarticle"
                          onSubmit={handleSubmit}
                        >
                          <fieldset>
                            <div className="form-group">
                              <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Title of Article *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <textarea
                                name="abstract"
                                className="form-control"
                                placeholder="Add Your Abstract *"
                                onChange={handleChange}
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reference"
                                className="form-control"
                                placeholder="Reference *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="keywords"
                                className="form-control"
                                placeholder="Keywords (separated by commas)"
                                onChange={handleChange}
                              />
                            </div>
                            <h3>Authors</h3>
                            <div className="form-group">
                              <input
                                type="text"
                                name="authors"
                                className="form-control"
                                placeholder="All authors name (separated by commas) *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="designations"
                                className="form-control"
                                placeholder="Designation (separated by commas) *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="affiliations"
                                className="form-control"
                                placeholder="Affiliation to Any Institute (separated by commas) *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="countries"
                                className="form-control"
                                placeholder="Country (separated by commas) *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="emails"
                                className="form-control"
                                placeholder="Email (separated by commas) *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="corresponding_email"
                                className="form-control"
                                placeholder="Corresponding Email *"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="contact_number"
                                className="form-control"
                                placeholder="Contact number *"
                                onChange={handleChange}
                              />
                            </div>
                            <h3>Suggest Reviewers</h3>
                            <h4>Reviewers 1</h4>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer1_name"
                                className="form-control"
                                placeholder="Name"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer1_designation"
                                className="form-control"
                                placeholder="Designation"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer1_field"
                                className="form-control"
                                placeholder="Field of expertise"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer1_contact"
                                className="form-control"
                                placeholder=" Contact number"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="reviewer1_email"
                                className="form-control"
                                placeholder="Email"
                                onChange={handleChange}
                              />
                            </div>
                            <h4>Reviewers 2</h4>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer2_name"
                                className="form-control"
                                placeholder=" Name"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer2_designation"
                                className="form-control"
                                placeholder=" Designation"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer2_field"
                                className="form-control"
                                placeholder="Field of expertise"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="reviewer2_contact"
                                className="form-control"
                                placeholder="Contact number"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="reviewer2_email"
                                className="form-control"
                                placeholder=" Email"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>Upload Pdf/Doc</label>
                              <input
                                type="file"
                                name="file"
                                className="form-control"
                                onChange={handleFileChange}
                              />
                              <small>File should be max 500kb</small>
                            </div>
                            <div className="form-group">
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value="Submit"
                              />
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3" style={styles.sideBar}>
                    <Siderbar />
                  </div>
                  <div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  mainCard: {
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
};

export default PaperCall;
