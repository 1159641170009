import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
     <footer id="sj-footer" className="sj-footer sj-haslayout">
        <div className="container">
          <div className="row">
            <Link className="sj-btnscrolltotop" to="javascript:void(0);"><i className="fa fa-angle-up" /></Link>
            <div className="sj-footercolumns">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 float-left">
                <div className="sj-fcol sj-footeraboutus">
                  <strong className="sj-logo">
                    <Link to="index.html"><img src="custom-image/footer-logo.png" alt="image description" /></Link>
                  </strong>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 float-left">
                <div className="sj-fcol sj-widget sj-widgetusefullinks">
                  <div className="sj-widgetheading">
                    <h3>About Papers</h3>
                  </div>
                  <div className="sj-description">
                    <p>NIETJOM is a research journal dedicated to publish articles, research & innovative ideas, case studies associated on all aspect of management and business aspects. </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 float-left">
                <div className="sj-fcol sj-widget sj-widgetresources">
                  <div className="sj-widgetheading">
                    <h3>Quick Links</h3>
                  </div>
                  <div className="sj-widgetcontent">
                    <ul>
                      <li><Link to="/contact-us">Contact Us</Link></li>
                      <li><Link to="/tnc">Terms &amp; Conditions</Link></li>
                      <li><Link to="/faq">FAQ</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 float-left">
                <div className="sj-fcol sj-widget sj-widgetcontactus">
                  <div className="sj-widgetheading">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="sj-widgetcontent">
                    <ul>
                      <li><i className="lnr lnr-home" /><address> 19, Knowledge Park-II,
                          Institutional Area, Greater Noida (UP), India Pincode-2013066
                        </address></li>
                      <li><Link to="mailto:editor@njom.org"><i className="lnr lnr-envelope" /><span>editor@njom.org</span></Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="sj-footerbottom">
              <p className="sj-copyrights">© 2022 <span>NIET Journal Of Management  (NIETJOM)</span>. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer