import React from 'react'
import { Link } from 'react-router-dom'
import Siderbar from './Siderbar'
const Faq = () => {
  return (
    <>
     <main id="sj-main" className="sj-main sj-haslayout sj-sectionspace">
        <div id="sj-twocolumns" className="sj-twocolumns">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                <div id="sj-content" className="sj-content">
                  <div className="sj-aboutus">
                    <div className="sj-introduction sj-sectioninnerspace">
                      <h4>FAQ</h4>
                      <h5>How Do I Submit My Paper To NJET Journal?
                      </h5>
                      <div className="sj-description">
                        <p>Authors can submit their manuscript only by online submission system.</p>
                      </div>
                      <h4>What Is The Template Used For Initial Submission Of Papers?</h4>
                      <div className="sj-description">
                        <p>All papers must be submitted in NJET journal template which can be downloaded from the home page link Downloads Template. Papers that don't follow NJET format will not be considered for review.
                        </p>
                      </div>
                      <h4>What Type Of File Format Is Acceptable?</h4>
                      <div className="sj-description">
                        <p>Only PDF/.Docx format is accepted.
                        </p>
                      </div>
                      <h4>Are All Submitted Articles Peer Reviewed?</h4>
                      <div className="sj-description">
                        <p>Yes, all submitted articles undergo peer review. The status of the paper will be informed within 15-30 days from the date of paper submission. Authors will be provided a week to revise and make changes suggested by the reviewers.
                        </p>
                      </div>
                      <h4>What Kind Of Papers Will Be Rejected Without Review?
                      </h4>
                      <div className="sj-description">
                        <p>Out of Scope papers, duplicate submissions and papers with improper formatting will be automatically rejected.
                        </p>
                      </div>
                      <h4>Is It Possible For Me To Talk To The Journal Editor Over Phone About My Paper?</h4>
                      <div className="sj-description">
                        <p>All inquiries and comments about manuscripts should be in writing. Keeping things in writing provides good record keeping, which is especially important for some reason in future.
                        </p>
                      </div>
                      <h4>Is There A Printed Version Of The Journal? Can I Order Additional Journal Copies? How Do I Do This?</h4>
                      <div className="sj-description">
                        <p>Yes, the journals are available in online &amp; print mode. You can order the same by mailing on  <Link href="mailto:submission@njet.org"><span>submission@njet.org</span></Link> 
                        </p>
                      </div>
                      <h4>I Submitted An Article Some Months Ago And Had No Response So Far, Whom Should I Ask Regarding This?</h4>
                      <div className="sj-description">
                        <p>Kindly, contact the Chief Editor of journal via contact us page.
                        </p>
                      </div>
                      <h4>Are There Any Discounts Available?
                      </h4>
                      <div className="sj-description">
                        <p>Authors need to pay the full amount for publication. The fee for only African nations is the discounted fee.
                        </p>
                      </div>
                      <h4>What Is The Refund Process For NJET Journal?
                      </h4>
                      <div className="sj-description">
                        <p>If an author request to withdraw his/her manuscript after paying the fee, no refund can be claimed. If article are found to be plagiarized even after publication, no refund can be claimed.
                        </p>
                      </div>
                      <h4>How To Obtain The Copy Right Form?
                      </h4>
                      <div className="sj-description">
                        <p>One can obtain from the link downloads on home page.
                        </p>
                      </div>
                    </div>
                    <div className="sj-ourhistory sj-sectioninnerspace">
                    </div>
                  </div>
                </div>
              </div>
             <Siderbar/>
            </div>
          </div>
        </div>
      </main>
    
    
    </>
  )
}

export default Faq