import React from "react";
import { Link } from "react-router-dom";
import Siderbar from "./Siderbar";
import Header from "./Header";
import Footer from "./Footer";

const ContactUs = () => {
  const styles = {
    mainCard: {
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      backgroundColor: "#fff",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
    },
  };

  return (
    <>
      <div style={styles.container}>
        <div className="container" style={styles.mainCard}>
          <div className="row justify-content-center">
            <div className="col-11">
              <div style={styles.mainContent}>
                <div className="row">
                  <div>
                    <Header />
                  </div>
                  <div className="col-lg-9 mt-4 mb-5">
                    <div id="sj-content" className="sj-content">
                      <div className="sj-aboutus">
                        <div className="sj-introduction sj-sectioninnerspace">
                          <h4>Contact Us</h4>
                          <h4>
                            NIET Journal Of Engineering &amp; Technology
                            (NIETJET) <br />
                            ISSN : 2229-5828 (Print)
                          </h4>
                          <div className="sj-description">
                            <p>
                              Research Cell NIET, Noida Institute of Engineering
                              &amp; Technology, 19, Knowledge Park-II,
                              Institutional Area, Greater Noida (UP), India
                              Pincode-2013066
                            </p>
                            Email:{" "}
                            <Link to="mailto:editor@Njet.org">
                              editor@njet.org
                            </Link>
                            ,{" "}
                            <Link to="mailto:editornjet@gmail.com">
                              editornjet@gmail.com
                            </Link>
                          </div>
                          <h4>Publisher Name &amp; Address</h4>
                          <div className="sj-description">
                            <p>
                              Research Cell NIET, Noida Institute of Engineering
                              &amp; Technology, 19, Knowledge Park-II,
                              Institutional Area, Greater Noida (UP), India
                              Pincode-2013066
                            </p>
                            Email:{" "}
                            <Link to="mailto:editor@Njet.org">
                              editor@njet.org
                            </Link>
                            ,{" "}
                            <Link to="mailto:editornjet@gmail.com">
                              editornjet@gmail.com
                            </Link>
                          </div>
                          <h4>Editorial And General Queries</h4>
                          <div className="sj-description">
                            <p>
                              Email:{" "}
                              <Link to="mailto:editor@Njet.org">
                                editor@njet.org
                              </Link>
                              ,{" "}
                              <Link to="mailto:editornjet@gmail.com">
                                editornjet@gmail.com
                              </Link>
                            </p>
                          </div>
                          <div className="sj-description">
                            <p>
                              For paper submission : Email:{" "}
                              <Link to="submission@njet.org">
                                submission@njet.org
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="sj-ourhistory sj-sectioninnerspace"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mb-4">
                    <Siderbar />
                  </div>
                  <div className="mt-5">
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
