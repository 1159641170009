import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom'
import Siderbar from './Siderbar';
const Tnc = () => {

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Fetch articles from the backend
    fetch('https://njom-426013.as.r.appspot.com/api/latest/articles')
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error fetching articles:', error));
  }, []);
  return (
    <>
     <main id="sj-main" className="sj-main sj-haslayout sj-sectionspace">
        <div id="sj-twocolumns" className="sj-twocolumns">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                <div id="sj-content" className="sj-content">
                  <div className="sj-aboutus">
                    <div className="sj-introduction sj-sectioninnerspace">
                      <h4>Terms Of Use</h4>
                      <div className="sj-description">
                        <p>The use of this website, its sub-sites and micro-sites are governed by the following terms of use:</p>
                      </div>
                      <h4>Privacy</h4>
                      <div className="sj-description">
                        <p>Any information submitted to or collected from our website, its sub-sites and micro-sites are ruled under our Privacy Policy.
                        </p>
                      </div>
                      <h4>General</h4>
                      <div className="sj-description">
                        <p>As a registered member of our website or services you may receive the mails regarding security, privacy, and administrative issues. We have the right to upgrade, change or modify the product, policy or content any time. The change will be effective from the moment of change.
                          You are agreed on the lawful use of the website and its content. It is considered that you are agreed upon the privacy and other policies using the website or our services and content. You will not upload any type of copyrighted material. You are exclusively liable for the secrecy of your account, username, and password.
                          A user or content loader of this website is directed not to upload or post any unethical material such as racist, defamatory, pornographic, false etc. We don’t discriminate on the basis of gender, age, religion, nationality or different ability of a person. Any tax applied by the Indian government will be applicable at source end.
                        </p>
                      </div>
                      <h4>Third-Party Advertisements</h4>
                      <div className="sj-description">
                        <p>The information displayed on the advertisement is not our liability. Any advertiser and their associate are having no connection with us.
                        </p>
                      </div>
                      <h4>Refund Policy</h4>
                      <div className="sj-description">
                        <p>You are responsible for your money deposited, and no refunds will be issued.
                        </p>
                      </div>
                      <h4>About Privacy</h4>
                      <div className="sj-description">
                        <p>The website is possessed and maintained by NIET. We do not favour or disfavour any religion or political party. We are dedicated to protect your privacy online or offline. We review our policy from on regular intervals, so the users are suggested to view the latest version. 
                          As a user of this website, it is considered that you are agreed on the privacy policy and term of use.
                        </p>
                      </div>
                      <h4>Data Collection</h4>
                      <div className="sj-description">
                        <p>Navigating our website does not require any personally identifiable data, until user wish to register with us. In some services, users are requested to register and provide some personal details (such as name, email address, phone number etc.) and obtain user account, user name, and password.
                          We do not collect excess personal data than is absolutely necessary for a service/activity. We analyze your general usage of the website, products, services, and courses. We only collect and analyse your data in an aggregate manner (without your personal identification). We observe statistical information such as website traffic, visits count, transactions duration, browsers used, language, IP address and operating system etc to enhance our website and to convey you pertinent information regarding our products and services may be significant for you. We do not sell the users’ personal data nor is it disclosed to any other third party. Once information submitted, we may retain it as long as we wish.
                          We reserve the right to disclose your personal information as required by law and in the Publication's interest to protect its property or other legal rights or the rights or property of others. In case of corporate reorganization, we reserve the right to transfer all the information we received from this website.
                        </p>
                      </div>
                      <h4>Data Uploading</h4>
                      <div className="sj-description">
                        <p>In some portions of the website, users upload data which will be treated as non-confidential (open access) and require no permission to download. We do not essentially support the views posted about your content anywhere. If someone posts any material on this website, he/she has to ensure that such materials will not violate any personal or proprietary rights of any third parties. We reserve the right to review, edit or delete any material posted by users which we believe objectionable.
                        </p>
                      </div>
                      <h4>International Users</h4>
                      <div className="sj-description">
                        <p>A user is considered as international if he possesses a non Indian citizenship. For international users the rules/laws pertaining to data may differ. Therefore, transferring your personal data you agreed to the use of your personal data for the uses in harmony with this Privacy Policy.
                        </p>
                      </div>
                      <h4>Payments</h4>
                      <div className="sj-description">
                        <p>NIETJET does not take any payments or endore any payouts for publishing articles. Anyone charging or exchanging any monetary value for the same shall be hold accountable. Please report the same to<Link href="mailto:editor@Njet.org"><span>editor@njet.org</span></Link> 
                        </p>
                      </div>
                      <h4>Use Of Cookies</h4>
                      <div className="sj-description">
                        <p>Our website uses cookies to increase our quality of services. You can set your browser to block cookies, or to warn you before accepting them. 
                        </p>
                      </div>
                      <h4>Security Measure</h4>
                      <div className="sj-description">
                        <p>The data with us is stored on secure servers. Registered users can access their account with their selected user name and password. Users are liable for keeping the password secret. Although the best possible efforts are applied to secure the data even then we cannot be held responsible for any security breach, as internet is not 100% secure. If we found any issue of security breach, we may inform you through email. 
                        </p>
                      </div>
                    </div>
                    <div className="sj-ourhistory sj-sectioninnerspace">
                    </div>
                  </div>
                </div>
              </div>
              <Siderbar/>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Tnc