import React from "react";
import Siderbar from "./Siderbar";
import Header from "./Header";
import Footer from "./Footer";

const Indexing = () => {
  const styles = {
    mainCard: {
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      backgroundColor: "#fff",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
    },
  };

  return (
    <>
      <div style={styles.container}>
        <div className="container" style={styles.mainCard}>
          <div className="row justify-content-center">
            <div className="col-11">
              <div style={styles.mainContent}>
                <div className="row">
                  <div>
                    <Header />
                  </div>
                  <div className="col-lg-9 mt-4 mb-5">
                    <div id="sj-content" className="sj-content">
                      <section className="sj-haslayout sj-sectioninnerspace">
                        <div className="sj-borderheading">
                          <h3>Indexing Details</h3>
                        </div>
                        <div className="container">
                          <div className="row">
                            <div className="col-4">
                              <img
                                src="./custom-image/mendeley.png"
                                alt="Company Logo 1"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/orcid.png"
                                alt="Company Logo 2"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/researchbib.png"
                                alt="Company Logo 3"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <img
                                src="./custom-image/ResearchGate.png"
                                alt="Company Logo 4"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/internet.png"
                                alt="Company Logo 5"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/scribd.png"
                                alt="Company Logo 6"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <img
                                src="./custom-image/sideshare.png"
                                alt="Company Logo 7"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/google.png"
                                alt="Company Logo 8"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/WOS.png"
                                alt="Company Logo 9"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <img
                                src="./custom-image/publons.png"
                                alt="Company Logo 10"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/studylib.png"
                                alt="Company Logo 11"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-4">
                              <img
                                src="./custom-image/indianci.png"
                                alt="Company Logo 12"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <img
                                src="./custom-image/ICI.png"
                                alt="Company Logo 13"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="col-lg-3" style={styles.sideBar}>
                    <Siderbar />
                  </div>
                  <div className="mt-5">
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Indexing;
