import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./styles.css";

function Header() {
  return (
    <>
      <header
        id="sj-header"
        className="sj-header sj-haslayout"
        style={styles.header}
      >
        <div className="container" style={styles.container}>
          <div className="row" style={styles.row}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="sj-topbar" style={styles.topbar}>
                <div className="sj-languagelogin">
                  <div className="sj-loginarea">
                    <ul className="sj-loging" style={styles.loginList}>
                      <li>
                        <Link to="#">Login</Link>
                      </li>
                      <li>
                        <Link to="#">Check Status</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="sj-navigationarea" style={styles.navigationArea}>
                <strong className="sj-logo">
                  <NavLink to="/" activeClassName="active">
                    <img src="custom-image/logo.png" alt="company logo here" />
                  </NavLink>
                </strong>
                <div className="sj-rightarea">
                  <nav id="sj-nav" className="sj-nav navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="lnr lnr-menu" />
                    </button>
                    <div
                      className=" navbar-collapse sj-navigation mb-3"
                      id="navbarNav"
                    >
                      <ul style={styles.navList}>
                        <li className="current-menu-item">
                          <NavLink to="/" exact activeClassName="active">
                            <i className="lnr lnr-home" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/editorial-board"
                            activeClassName="active"
                          >
                            Editorial board
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/paper-call" activeClassName="active">
                            Call for paper
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/indexing" activeClassName="active">
                            Indexing
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/archives" activeClassName="active">
                            Archives
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/join-us" activeClassName="active">
                            Join Us
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact-us" activeClassName="active">
                            Contact Us
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/thrust-area" activeClassName="active">
                            Thrust Area
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

const styles = {
  header: {
    margin: 0,
    padding: 0,
  },
  container: {
    padding: 0,
    margin: 0,
  },
  row: {
    padding: 0,
    margin: 0,
  },
  topbar: {
    padding: 0,
    margin: 0,
  },
  loginList: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  navigationArea: {
    padding: 0,
    margin: 0,
  },
  navList: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
};

export default Header;
