import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css"; // Ensure this path is correct
import Siderbar from "./Siderbar";
import Header from "./Header";
import Footer from "./Footer";

function Archives() {
  const [volumes, setVolumes] = useState([]);
  const [activeVolume, setActiveVolume] = useState(1);
  const navigate = useNavigate(); // useNavigate for navigation

  useEffect(() => {
    axios
      .get("https://njom-426013.as.r.appspot.com/api/volumes")
      .then((response) => setVolumes(response.data))
      .catch((error) => console.error("Error fetching volumes:", error));
  }, []);

  const handleAccordionClick = (volumeNumber) => {
    setActiveVolume(activeVolume === volumeNumber ? null : volumeNumber);
  };

  const handleViewAllArticlesClick = (volumeNumber, season, year) => {
    navigate(`/archives/${volumeNumber}/${season}/${year}`);
    // console.log(volumeNumber)
  };

  

  return (
    <div style={styles.container}>
      <div className="container" style={styles.mainCard}>
        <div className="row justify-content-center">
          <div className="col-11">
            <div style={styles.mainContent}>
              <div className="row">
                <div>
                  <Header />
                </div>
                <div className="col-lg-9 mt-4 mb-5">
                  <div id="sj-content" className="sj-content">
                    {volumes.map((volume) => (
                      <div className="volume" key={volume.volume_number}>
                        <button
                          className={`accordion ${
                            activeVolume === volume.volume_number
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleAccordionClick(volume.volume_number)
                          }
                        >
                          {`Volume ${volume.volume_number}`}
                        </button>
                        <div
                          className={`panel ${
                            activeVolume === volume.volume_number ? "open" : ""
                          }`}
                        >
                          {volume.winter_volume && (
                            <div className="issue">
                              <h4>Winter Issue - Published</h4>
                              <img
                                src={`${process.env.PUBLIC_URL}/images/volumes-cover/${volume.winter_volume}.png`}
                                alt={`Winter Issue cover`}
                              />
                              <div className="buttons">
                                <button>View Cover Sheet</button>
                                <button
                                  onClick={() =>
                                    handleViewAllArticlesClick(
                                      volume.volume_number,
                                      volume.winter_season,
                                      volume.winter_year
                                    )
                                  }
                                >
                                  View All Articles
                                </button>
                              </div>
                            </div>
                          )}
                          {volume.summer_volume && (
                            <div className="issue">
                              <h4>Summer Issue - Published</h4>
                              <img
                                src={`${process.env.PUBLIC_URL}/images/volumes-cover/${volume.summer_volume}.png`}
                                alt={`Summer Issue cover`}
                              />
                              <div className="buttons">
                                <button>View Cover Sheet</button>
                                <button
                                  onClick={() =>
                                    handleViewAllArticlesClick(
                                      volume.volume_number,
                                      volume.summer_season,
                                      volume.summer_year
                                    )
                                  }
                                >
                                  View All Articles
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-3 mb-4">
                  <Siderbar />
                </div>
                <div className="mainFotter mt-5" style={styles.mainFotter}>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const styles = {
  mainCard: {
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  mainFotter: {
    
  },
};

export default Archives;
