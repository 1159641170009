import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; 
import Siderbar from './Siderbar';
import downloadIcon from '../download.svg';
import { useNavigate } from 'react-router-dom';

function Volume() {
  const { volume_id, season, year } = useParams();
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://njom-426013.as.r.appspot.com/api/articles/all')
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error fetching articles:', error));
  }, [volume_id, season, year]);


  const viewFullArticle =(id)=>{
    navigate(`/article/${id}`);
  }

  return (
    <div className='sj-content'>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-9">
            <div id="sj-content" className="sj-content">
              {/* Articles */}
              <div className="articles">
                {articles
                  .filter(article => article.volume_id === parseInt(volume_id) && article.file_url && article.file_url.includes(season))
                  .map((article, index) => (
                    <div key={index} className="article" style={styles.article}>
                      <p>{article.name}</p>
                      <h3>{article.title}</h3>
                      <p>{article.abstract}</p>
                      <div style={styles.buttons}>
                        <button style={styles.button} onClick={() => viewFullArticle(article.id)}>View Full Article</button>
                        <button style={styles.button}>
                          <img src={downloadIcon} alt="Download" style={styles.icon} /> View PDF
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <Siderbar />
        </div>
      </div>
    </div>
  );
}

const styles = {
  heroBanner: {
    backgroundColor: '#f8f9fa',
    padding: '50px 20px',
    textAlign: 'center',
  },
  heroImage: {
    width: '80%',
  },
  article: {
    marginBottom: '20px',
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '5px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '1px 2px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '5px',
    width: '30px',
  },
  head: {
    marginLeft: '12px',
    padding: '50px'
  },
};

export default Volume;
