import React from 'react';
import Siderbar from './Siderbar';
import Header from './Header';
import Footer from './Footer';

const TrustArea = () => {
  const styles = {
    mainCard: {
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      backgroundColor: '#fff',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }
  };

  return (
    <>
      <main style={styles.container}>
        <div className="container" style={styles.mainCard}>
          <div className="row justify-content-center">
            <div className="col-11">
              <div style={styles.mainContent}>
                <div className="row">
                  <div>
                    <Header />
                  </div>
                  <div className="col-lg-9 mt-4 mb-5">
                    <div id="sj-content" className="sj-content">
                      <div className="sj-aboutus">
                        <div className="sj-introduction sj-sectioninnerspace">
                          <h4>Aim</h4>
                          <div className="sj-description">
                            <p>
                              The journal's principal goal is to provide a platform
                              for students, scholars, researchers, professionals,
                              and industrialists to communicate their discoveries
                              and research contributions. NIETJET focuses on
                              advancing knowledge and sharing it with people all
                              around the world. The papers that are published will
                              broaden the knowledge of present scholars and inspire
                              newcomers in the all fields of Engineering &amp;
                              Technology. Only high-quality research papers in the
                              areas of original Research Articles, Review Articles,
                              Survey Articles, Case Study are accepted by NIETJET.
                              The NIETJET is adamant about accepting research
                              findings on current issues with significant social
                              significance.
                            </p>
                          </div>
                          <h4>Scope</h4>
                          <div className="sj-description">
                            <p>
                              The Publication of NIETJET is an interdisciplinary
                              refereed journal that covers the all fields of
                              Engineering &amp; Technology. NIETJET aspires to
                              achieve a degree of predictive power that is a strong
                              complement to experimentation and theory. Original,
                              unpublished high-quality submissions in the following
                              thrust areas (but not limited to) are welcome for
                              publication in the NIETJET:
                            </p>
                          </div>
                          <h4>Thrust Areas</h4>
                          <ol>
                            <li style={{ fontSize: "22px" }}>Basic Science:</li>
                            <div className="sj-description">
                              <p>
                                Chemistry &amp; Biochemistry, Nanotechnology,
                                Astronomy and Physics, Neurobiology, Ecology,
                                Mathematics &amp; Applied Mathematics, Pharmency
                                &amp; Application, etc.
                              </p>
                              <br />
                            </div>
                            <li style={{ fontSize: "22px" }}>Engineering</li>
                          </ol>
                          <ul>
                            <li>
                              <b>Computer Science &amp; Engineering</b>
                            </li>
                            <div className="sj-description">
                              <p>
                                Advanced Computing,Algorithms and
                                Bioinformatics,Biometrics, Network Security and
                                Information Assurance, System Software, Soft
                                Computing (AI, Neural Net., Fuzzy Systems, etc.),
                                Internet Computing, Wireless Ad Hoc / Sensor
                                Networks, Communications, Computer Architecture and
                                Real time Systems, Computer Graphics, Database and
                                Data Mining, E-Commerce Systems, Dependable,
                                Reliable and Autonomic Computing, E-Community, DSP /
                                Image Processing / Pattern Recognition,
                                E-Governance, Distributed and Parallel Systems &amp;
                                Algorithms, E-Learning, Intelligent Information
                                &amp; Database Systems, Embedded Sys. and Software,
                                Information Coding and Compression Techniques,
                                Information Retrieval, IT Policy and Business
                                Management, Knowledge Management, Expert Systems
                              </p>
                            </div>
                            <li>
                              <b>Electronics and Communication Engineering</b>
                            </li>
                            <div className="sj-description">
                              <p>
                                Wireless Power Transmission via Solar Power
                                Satellite, Adaptive optics, Circuit Breaker
                                Maintenance by Mobile Agent Software Technology,
                                Digital Testing of High Voltage Circuit Breaker,
                                Eddy current brakes, Electric Powerline Networking
                                For A Smart Home, Electrical Impedance Tomography,
                                Electro Dynamic Tether, Flexible Ship Electric Power
                                System Design, Hybrid Electric Vehicle, Hy-Wire,
                                Illumination with Solid State lighting, Intelligent
                                Management Of Electrical Systems in Industries,
                                Isoloop Magnetic Couplers, Large Scale Power
                                Generation Using Fuel Cell, Local Multipoint
                                Distribution Service, Low - k DielectricsS, Mesh
                                Radio, MicroGrid, Nuclear Batteries, Optical
                                Interconnects, Optical Technology in Current
                                Measurement, PEA Space Charge Measurement System,
                                Pebble-Bed Reacto, Polyfuse, Power Quality, Robotic
                                control Using Fuzzy Logic, Robotic Monitoring of
                                Power Systems, Snake Robot, Surge current protection
                                using superconductors, Robotic Monitoring of Power
                                Systems, The Universal Current Sensor,
                                Thermomechanical Data Storage, Ultraconductors, Ipv6
                                - The Next Generation Protocol, Bio-metrics, Compact
                                peripheral component interconnect, Iontophoresis,
                                Human-Robot Interaction, Crusoe Processor,
                                Honeypots, Hydrogen Super Highway, IBOC Technology,
                                Border Security Using Wireless Integrated Network
                                Sensors, Blast, CT Scanning, IP Telephony, Class-D
                                Amplifier, Co-operative cache based data access in
                                ad hoc networks, Boiler Instrumentation and
                                Controls, CorDECT, IsoLoop magnetic couplers, Cruise
                                Control Devices, CRT Display, Crusoe, Coherent
                                Acoustics Coding System, Adaptive Missile Guidance
                                Using GPS, Iris Scanning, Bluetooth Based Smart
                                Sensor Networks, Infinite Dimensional Vector Space,
                                Computer Aided Process Planning, AC Performance Of
                                Nanoelectronics, Aeronautical Communication,
                                Acoustics, Aluminum Electrolytic Capacitors, AI for
                                Speech Recognition, Anthropomorphic Robot hand,
                                Continuously variable transmission, Code division
                                multiple access, Automatic Teller Machine, Automatic
                                Number Plate Recognition, An ATM With An Eye,
                                Autonomous Underwater Vehicle, Adaptive Optics in
                                Ground Based Telescopes, Code Division Duplexing,
                                Adaptive Blind Noise Suppression in some Speech
                                Processing Applications, Bio Battery, Bio-Molecular
                                Computing, Black-Box, BIT for Intelligent system
                                design, Analog-Digital Hybrid Modulation, Blu Ray
                                Disc, BiCMOS technology, An Efficient Algorithm for
                                iris pattern, Mobile Train Radio Communication,
                                Molecular Electronics, MOBILE IPv6, Animatronics,
                                Bioinformatics, BlueStar, Asynchronous Transfer
                                Mode, ANN for misuse detection, MIMO Wireless
                                Channels, Mobile IP, MILSTD 1553B, A BASIC
                                TOUCH-SENSOR SCREEN SYSTEM, Mobile virtual reality
                                service, High Performance DSP Architectures,
                                Integrated Power Electronics Module
                              </p>
                            </div>
                            <li>
                              <b>Mechanical Engineering</b>
                            </li>
                            <div className="sj-description">
                              <p>
                                Composite Materials, Computational Intelligence in
                                Mechatronics, Computer and Robot Vision, Mechanical
                                Engineering, Automobile, Industralisation and
                                Production Engineering, Bio Mechanical, Computer
                                Control, Control system modeling and simulation
                                techniques and methodologies, Control Systems and
                                Machine Dynamics, Cutting Toll Design, Discrete
                                Event Dynamic Systems, Distributed Systems,
                                Dynamics, Motion Control
                              </p>
                            </div>
                            <li>
                              <b>Civil Engineering</b>
                            </li>
                            <div className="sj-description">
                              <p>
                                Surveying-land and surveying, Material science and
                                engineering, Coastal engineering, Construction
                                engineering, Building Technology, Structural
                                Analysis &amp; Design, Design of Hydraulic
                                Structures, Architecture and Town Planning
                              </p>
                            </div>
                            <li>
                              <b>Electrical Engineering</b>
                            </li>
                            <div className="sj-description">
                              <p>
                                SModular Embedded Internet Software Support,
                                High-availability power systems, HART Communication,
                                High Altitude Aeronautical Platforms, Heliodisplay,
                                Home Networking, Motes, Moletronics- an invisible
                                technology, Nanotechnology, M-Voting, Nanorobotics,
                                Josephson Junction, Navbelt and Guiecane, Modern
                                Irrigation System Towards Fuzzy, Integer Fast
                                Fourier Transform, Holographic Data Storage,
                                Intelligent Wireless Video Camera, Integrated Voice
                                &amp; Data, Internet Protocol Television,
                                Introduction to the Internet Protocols, Holographic
                                Versatile Disc, Intervehicle Communication,
                                Humanoids Robotics, Modems and ISDN, Multissensor
                                Fusion and Integration, Narrow Band &amp; Broad Band
                                ISDN, Augmented reality, Clos Architecture in OP
                              </p>
                            </div>
                            <li>
                              <b>Chemical Engineering</b>
                            </li>
                            <div className="sj-description">
                              <p>
                                Bioengineering and Health, Clean Energy
                                Technologies, Food Science and Technology, Polymer
                                Chemistry, Process Systems Engineering, Water
                                Treatment
                              </p>
                            </div>
                          </ul>
                        </div>
                        <div className="sj-ourhistory sj-sectioninnerspace"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mb-4">
                    <Siderbar />
                  </div>
                  <div className='mt-5'>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default TrustArea;
