import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Header from "./component/Header";
import Home from "./component/Home";
import EditorialBoard from "./component/EditorialBoard";
// import  PaperCall  from "./component/PaperCall";
import Faq from "./component/Faq";
// import  Archives  from "./component/Archives";
import ContactUs from "./component/ContactUs";
import JoinUs from "./component/JoinUs";
import Tnc from "./component/Tnc";
import TrustArea from "./component/TrustArea";
import Indexing from "./component/Indexing";
import { PaperCall } from "./component/PaperCall";
import Archives from "./component/Archives";
// import Siderbar from "./component/Siderbar";
import Volume from "./component/Volume";
import Article from "./component/Article";


function App() {
  return (
    <>
    <BrowserRouter>
   
    

     <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/editorial-board" element={<EditorialBoard/>}></Route>
      <Route path="/paper-call" element={<PaperCall/>}></Route>
      <Route path="/faq" element={<Faq/>}></Route>
        
        <Route path="/contact-us" element={<ContactUs/>}></Route>
          <Route path="/join-us" element={<JoinUs/>}></Route>
          <Route path="/tnc" element={<Tnc/>}></Route>
          <Route path="/thrust-area" element={<TrustArea/>}></Route>
          <Route path="/indexing" element={<Indexing/>}></Route>
          <Route path="/archives" element={<Archives/>}></Route>
      
          <Route path="/archives" component={<Archives/>} ></Route>
          <Route path="/archives/:volume_id/:season/:year" element={<Volume />}></Route>
          <Route path="/article/:id" element={<Article />}></Route>


        
   
     </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
