import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Siderbar.css";

const Siderbar = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Fetch articles from the backend
    fetch("https://njom-426013.as.r.appspot.com/api/latest/articles")
      .then((response) => response.json())
      .then((data) => setArticles(data))
      .catch((error) => console.error("Error fetching articles:", error));
  }, []);

  return (
    <div className="custom-sidebar-card">
      <aside className="custom-sidebar">
        <div className="custom-widget custom-widget-downloads">
          <div className="custom-widget-heading">
            <h3>Downloads</h3>
          </div>
          <div className="custom-widget-content">
            <ul>
              <li>
                <Link to="#">Paper Format</Link>
              </li>
              <li>
                <Link to="#">Copyright Form</Link>
              </li>
              <li>
                <Link to="#">Paper Writing Tips</Link>
              </li>
            </ul>
          </div>
          <Link className="custom-btn" to="/paper-call">
            Add Article
          </Link>
        </div>
        <div className="custom-widget custom-widget-articles">
          <div className="custom-widget-heading">
            <h3>Latest Articles</h3>
          </div>
          <div className="custom-widget-content">
            <ul>
              {articles.map((article, index) => (
                <li key={index}>
                  <Link to="#">{article.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Siderbar;
