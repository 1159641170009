import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Siderbar from './Siderbar';

function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    axios.get('https://njom-426013.as.r.appspot.com/api/articles/all')
      .then(response => {
        const foundArticle = response.data.find(article => article.id === parseInt(id));
        setArticle(foundArticle);
      })
      .catch(error => console.error('Error fetching article:', error));
  }, [id]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className='sj-content'>
      {/* Hero Banner */}
      
      
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-9">
            <div id="sj-content" className="sj-content">
              <div className="article-detail">
                <h2>{article.title}</h2>
                {/* <h4>By {article.name}</h4> */}
                <p>{article.abstract}</p>
                <p>{article.full_text}</p>
                <div style={styles.buttons}>
                  <a href={article.pdf_link} target="_blank" rel="noopener noreferrer" style={styles.button}>
                    View PDF
                  </a>
                  <button style={styles.button}>Share On Social</button>
                </div>
              </div>
            </div>
          </div>
        <Siderbar/>
        </div>
      </div>
    </div>
  );
}

const styles = {
  heroBanner: {
    backgroundColor: '#f8f9fa',
    padding: '50px 20px',
    textAlign: 'center',
  },
  heroImage: {
    width: '80%',
  },
  article: {
    marginBottom: '20px',
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '5px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '5px',
    width: '20px',
  },
  head: {
    marginLeft: '12px',
    padding: '50px'
  }
};

export default Article;
